import React, {useContext} from 'react'
import './Image.css'
import {RenderModeContext} from 'utils/Context.js'
import {FormImage} from 'components/form/FormItems.js'
import ImageWidget from 'components/image/Image.js'

const RenderMode = ({data, className}) => <ImageWidget className={`${className} image`} name={data.image} /> 

const EditMode = ({name, data}) => <>
	<FormImage label="Image" name={[...name, 'image']} />
</>

export default function Image(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}
