
import React, {useContext} from 'react'
import Handlebars from 'handlebars'
import {LangContext, RoleContext} from 'utils/Context.js'

export default function String(props) {

	const lang = useContext(LangContext)
	const role = useContext(RoleContext)

	if (props.children === undefined) return null

	const result = getStringValue(props.children, lang, role.mainLang, props.dontBreak)
	
	return <span className={props.className} onClick={props.onClick} dangerouslySetInnerHTML={{__html: result}}></span>
}

export const getStringValue = (value, lang, mainLang, dontBreak) => {

	if (!lang) throw new Error('lang is mandatory')

	let result = value

	if (result === undefined) return null

	let hasTranslation = true;

	if (result && typeof result === 'object') {

		if (result[lang]) {
			hasTranslation = true
		}	else {
			let trad = result[mainLang] ? result[mainLang] : result.FR
			if(trad) trad = trad.replaceAll(/\(\([^)]+\)\)|<[^>]+>|[\s\d+-]/g, '').trim()

			hasTranslation = !trad || trad === ''
		}

		result = result[lang] ? result[lang] : result[mainLang] ? result[mainLang] : result.FR
	} else if (typeof result === 'number') {
		result = result + ''
	} 

	if (!result) return null

	// handlebar !
	// convert (()) to {{}} to be compliant with handlebars
	result = result.replace(/\(\(([^()]*(\(b\)")?)\)\)/g, '{{$1}}')

	if (!dontBreak) {
		// keep line break
		result = result.replace(/([^>])\n([^<])/g, '$1<br>$2')
	}
	
	// compile helpers
	try {
		result = Handlebars.compile(result)(result)
	} catch (e) {
		result = `<span style="color: red">${result}</span>`
	}

	if (!hasTranslation) result = `<div class="missing-translation">${result}</div>`

	return result
}