import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IKImage } from 'imagekitio-react'
import {API} from '../utils/API.js'

export default function Reorder({cards, file, branch, language, setLoading, pushChange}) {

	let pairs = []
	// transform to pair model
	cards.forEach((c, idx) => {
		if (idx % 2 !== 0) return

		pairs.push({id: c.id, recto: c, verso: cards[idx+1]})
	})

	const [data, setData] = useState(pairs)

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
	
		return result
	}

	const onDragEnd = (result) => {
		// dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      data,
      result.source.index,
      result.destination.index
    )

		setData(items)
	}

	const save = async () => {
		setLoading(true)
		const cards = await API.getContent(file, branch)

		const map = {}
		data.forEach((c, idx) => {
			map[c.id] = idx
		})

		cards.sort((c1, c2) => {

			const item1 = map[parseInt(c1.id)]
			const item2 = map[parseInt(c2.id)]

			const idx1 = item1 !== undefined ? item1 : -1
			const idx2 = item2 !== undefined ? item2 : -0

			return idx1 >= idx2 ? 1 : -1
		})

		pushChange(file, cards)
	}

	const content = data.map((c, idx) => {
	
		const id = c.id
		let recto = null
		let verso = null
		const transformation = [{
			width: 300,
			height: 300,
			c: 'at_max'
		}]

		if (c.recto.print && c.recto.print[language]) {
			recto = <IKImage key={`card${id}`} path={`/print/${language}/${file}/${c.recto.print[language]}`}
					transformation={transformation} />
		} else {
			recto = <div className="error">This image should be rendered again. Ask Jerome to do so.</div>
		}
	
		if (c.verso.print && c.verso.print[language]) {
			verso = <IKImage key={`card${id}b`} path={`/print/${language}/${file}/${c.verso.print[language]}`}
					transformation={transformation} />
		} else {
			verso = <div class="error">This image should be rendered again. Ask Jerome to do so.</div>
		}
	
		return <Draggable key={id} index={idx} draggableId={id}>
			{(provided) => <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
				{recto}{verso}
			</li>}
		</Draggable>
	})

	return <>
		<div className="form">
			<button onClick={save}>Save</button>
		</div>
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="cards">
				{(provided) => <ul  {...provided.droppableProps} ref={provided.innerRef}>
					{content}
					{provided.placeholder}
				</ul>}
			</Droppable>
		</DragDropContext>
	</>
}