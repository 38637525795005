const ENV = 'PROD'

const PARAM = {
	'DEV': {
		url: 'http://localhost:3000'
	},
	'PROD': {
		url: 'https://2vwfc8nlha.execute-api.us-east-1.amazonaws.com/prod/heredity'
	}
}[ENV]

const showError = message => {
	window.alert(message)
}

const doFetch = async (path, params) => {
	const response = await fetch(path, params).catch(error => {
		showError(error.message)
	})

	const text = await response.text()
	if (response.status === 500) {
		localStorage.removeItem('token')
		showError(text)
		window.location.href= '/'
		return null
	}

	return text
}

const getToken = () => {
	return localStorage.getItem('token')
}

export const API = {
	login: async (login, password) => {
		return await doFetch(PARAM.url, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
				action: 'login',
				login: login,
				password: password
			})
		})
	},

	getPermissions: async() => {
		const result = await doFetch(PARAM.url, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
				action: 'auth',
				token: getToken()
			})
		})

		return JSON.parse(result)
	},

	getContent: async function(file, branch) {
		console.log('get content')
		const path = `${PARAM.url}?file=${file}&branch=${branch}&action=getContent&token=${getToken()}`
		const result = await doFetch(path)
		return JSON.parse(result)
	},

	pushContent: async function(branch, file, content) {
		console.log('UPLOAD: ')
		console.log(content)

		//if (false)
		await doFetch(PARAM.url, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
				action: 'saveContent',
				file: file,
				branch: branch,
				content: JSON.stringify(content, null, 2),
				token: getToken()
			})
		})
	}
} 