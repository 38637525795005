import React, {useContext} from 'react'
import Gem from 'components/gem/Gem.js'
import './Character.css'
import {FormSelect, FormImage, FormRichText} from 'components/form/FormItems.js'
import {RenderModeContext} from 'utils/Context.js'
import Anchors from 'components/anchors/Anchors.js'
import Image from 'components/image/Image.js'
import String from 'components/string/String.js'
import { FormCheckbox } from '../../form/FormItems.js'
import { Select } from 'antd'

const RenderMode = ({data}) => {
	const name = (data.subtype === 'head') ? <div className="character-name"><String>{data.name}</String></div> : null
	let action = 'interact'
	let action2 = 'interact'
	let anchors = null

	switch (data.subtype) {
		case 'head': 
			action = (data.injured) ? 'discuss-and-look' : 'discuss'
			action2 = 'look'
			anchors = <Anchors data={{N: 'top'}} />
		break
		case 'body': action = action2 = 'interact'
		break
		case 'foot': action = action2 = data.child ? 'move-child' : 'move'
		break
		default:
	}

	const action1Props = {
		color: data.color,
		action: action,
		heart: true,
		noFight: data.child
	}

	const action2Props = (!data.injured) ? (
		<div className="character-action-2">
			<Gem color={data.color} action={action2} heart={true} noFight={data.child} />
		</div>
	) : null

	const action3Props = (action === 'move' || action === 'move-child') ? (
		<div className="character-action-3">
			<Gem color={data.color} action={action} noFight={data.child} />
		</div>
	) : null

	const image = <Image className="character-bg" name={data.image} />

	return <>
		{image}
		<span className="character-connector"></span>
		<span className="character-connector-bag"></span>
		{name}
		<div className="character-action-1">
			<Gem {...action1Props} />
		</div>
		{action2Props}
		{action3Props}
		{anchors}
	</>
}

const EditMode = ({data}) => <>
	<FormSelect label="Type" name="subtype">
		<Select.Option value=""> - </Select.Option>
		<Select.Option value="head">Head</Select.Option>
		<Select.Option value="body">Body</Select.Option>
		<Select.Option value="foot">Foot</Select.Option>
	</FormSelect>
	{data.subtype === 'head' && <FormRichText label="Name" name="name" />}
	<FormImage label="Image" name="image" />
	<FormSelect label="Gem color" name="color">
		<Select.Option value=""> - </Select.Option>
		<Select.Option value="gray">gray</Select.Option>
		<Select.Option value="red">red</Select.Option>
		<Select.Option value="blue">blue</Select.Option>
		<Select.Option value="green">green</Select.Option>
		<Select.Option value="yellow">yellow</Select.Option>
		<Select.Option value="purple">purple</Select.Option>
	</FormSelect>
	<FormCheckbox label="Serious injury" name="injured" />
	<FormCheckbox label="Child" name="child" />
</>

export default function Character(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}
