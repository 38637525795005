import React, { useContext, useState } from 'react'
import { Editor as TextEditor } from '@tinymce/tinymce-react'
import {LangContext, ImageContext} from 'utils/Context.js'
import Image from 'components/image/Image.js'
import { IKUpload } from 'imagekitio-react'
import './FormItems.css'
import { DeleteTwoTone, PlusCircleTwoTone, UpCircleTwoTone, UpCircleFilled, DownCircleTwoTone, DownCircleFilled} from '@ant-design/icons'
import { Input, Select, Form as FormAnt, Button, Flex, Divider, Switch, Space, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form.js'
import { RoleContext } from '../../utils/Context.js'

export const Form = ({data, onChange, children}) => {

	const [form] = useForm()

	form.setFieldsValue(data)

	return <FormAnt form={form} layout="vertical" initialValues={data} onValuesChange={v => onChange({...data, ...form.getFieldsValue()})}>
		{children}
	</FormAnt>
}

export const FormDivider = (title) => {
	const lang = useContext(LangContext)
	const role = useContext(RoleContext)
	if (!role.canFullEdit) return null
	
	return <Divider title={title}/>
}

export const FormText = ({label, placeholder, name, style}) => {
	const lang = useContext(LangContext)
	const role = useContext(RoleContext)
	if (!role.canFullEdit) return null

	return (
		<FormAnt.Item label={label} name={name} style={style}>
			<Input placeholder={placeholder} />
		</FormAnt.Item>
	)
}

export const FormCheckbox = ({label, name, style}) => {
	const lang = useContext(LangContext)
	const role = useContext(RoleContext)
	if (!role.canFullEdit) return null
	
	return (
		<FormAnt.Item label={label} name={name} valuePropName="checked" style={style}>
			<Switch />
		</FormAnt.Item>
	)
}

export const FormSelect = ({label, name, children, mode, style}) => {
	const lang = useContext(LangContext)
	const role = useContext(RoleContext)
	if (!role.canFullEdit) return null
	
	return (
		<FormAnt.Item label={label} name={name} style={style}>
			<Select mode={mode} popupMatchSelectWidth={false}>
				{children}
			</Select>
		</FormAnt.Item>
	)
}

const ImageUploader = ({value, onChange}) => {

	const lang = useContext(LangContext)
	const role = useContext(RoleContext)

	const imageContext = useContext(ImageContext)
	const [uploading, setUploading] = useState(false)

	const getContent = (value, lang, fallbackOnDefault = false) => {

		let result = null

		if (typeof value === 'object') {
			result = value[lang]

			if (!result && fallbackOnDefault) result = value.FR
		} else if (lang === 'FR' || fallbackOnDefault) result = value

		return result
	}

	const update = (updatedValue, lang) => {

		let result = {}
		if (typeof value === "string") {
			// init FR value (it will be overriden or the current lang is also FR)
			result['FR'] = value
			result[lang] = updatedValue
		} else {
			result = {...value}
			result[lang] = updatedValue
		}

		onChange(result)
		setUploading(false)
	}

	return <Spin tip="Uploading..." spinning={uploading}>
		<Flex gap="small" vertical>
			<Flex gap="small" align="center">
				{lang !== role.mainLang && <>{role.mainLang}</>} 
				<Image name={getContent(value, role.mainLang, true)} style={{width: '100px', height: '100px', border: 'solid 1px black', borderRadius: '3px'}} transformation={[{
					'height': '100',
					'width': '100'
				}]}/>
				{role.canFullEdit && <IKUpload folder={imageContext.path} onSuccess={res => {update(res.name, role.mainLang)}} onUploadStart={() => setUploading(true)} />}
			</Flex>

			{lang !== role.mainLang &&  
				<Flex gap="small" align="center">
					{lang} 
					<Image name={getContent(value, lang)} style={{width: '100px', height: '100px', border: 'solid 1px black', borderRadius: '3px'}} transformation={[{
						'height': '100',
						'width': '100'
					}]}/>
					<IKUpload folder={imageContext.path} onSuccess={res => {update(res.name, lang)}} onUploadStart={() => setUploading(true)} />
					<DeleteTwoTone onClick={() => update(null, lang)}/>
				</Flex>
			}
		</Flex>
	</Spin>
}

export const FormImage = ({label, name}) => {
	return <>
		<FormAnt.Item label={label} name={name}>
			<ImageUploader />
		</FormAnt.Item>
	</>
}

const MapEditor = ({value, onChange, image}) => {
	const SIZE = 100/6

	const [record, setRecord] = useState(value ? value : [])
	const [lastPoint, setLastPoint] = useState()
	const [isRecording, setRecording] = useState(false)

	const figs = []
	for (let x = 0; x <= 6; x++) {
		figs.push(<line 
			key={`col${x}`} 
			x1={`${x*SIZE}%`} 
			x2={`${x*SIZE}%`} 
			y1={`0%`} 
			y2={`100%`} 
			/>)
	}
	for (let y = 0; y <= 6; y++) {
		figs.push(<line 
			key={`row${y}`} 
			y1={`${y*SIZE}%`} 
			y2={`${y*SIZE}%`} 
			x1={`0%`} 
			x2={`100%`} 
			/>)
	}

	const startRecord = (x,y) => {
		setLastPoint({x: x, y: y})
		setRecording(true)
	}

	const recordPoint = (x, y) => {
		if (!isRecording || (x === lastPoint.x && y === lastPoint.y)) return 

		const idx = record.findIndex(i => i !== null &&
			((i.x1 === x && i.y1 === y && i.x2 === lastPoint.x && i.y2 === lastPoint.y) || 
			(i.x2 === x && i.y2 === y && i.x1 === lastPoint.x && i.y1 === lastPoint.y))
		)

		let result = [...record]
		if (idx === -1) result.push({x1: lastPoint.x, x2: x, y1: lastPoint.y, y2: y})
		else result.splice(idx, 1)

		setRecord(result)
		onChange(result)
		setLastPoint({x: x, y: y})
	}

	const stopRecord = () => {
		setRecording(false)
	}

	const removeRecord = r => {
		const result = [...record]
		result.splice(result.indexOf(r), 1)
		setRecord(result)
		onChange(result)
	}

	const clearMap = () => {
		setRecord([])
		onChange([])
	}

	const circles = []
	for (let x = 0; x <= 6; x++) {
		for (let y = 0; y <= 6; y++) {
			circles.push(
				<circle key={`${x}${y}`} cx={`${x*SIZE}%`} cy={`${y*SIZE}%`} r="10" onMouseDown={e => startRecord(x,y)} onMouseEnter={e => recordPoint(x,y)} />
			)
		}
	}

	const selectedLines = record.map(i => i ? <line 
		key={`row${JSON.stringify(i)}`} 
		x1={`${i.x1*SIZE}%`} 
		x2={`${i.x2*SIZE}%`}
		y1={`${i.y1*SIZE}%`} 
		y2={`${i.y2*SIZE}%`}  
		onClick={e => removeRecord(i)}
		/> : null)

	return <>
		<Flex vertical gap="small">
			<div className="form-map">
				{image && <Image name={image} transformation={[{'height': '200','width': '200'}]}/>}
				<svg onMouseUp={stopRecord} onMouseLeave={stopRecord}>
					{figs}
					<g className="form-map-selected">
						{selectedLines}
					</g>
					{circles}
				</svg>
			</div>
			<Button onClick={clearMap}>Clear</Button>
		</Flex>
	</>
}

export const FormMap = ({label, name, image}) => {
	const lang = useContext(LangContext)
	const role = useContext(RoleContext)
	if (!role.canFullEdit) return null

	return <>
		<FormAnt.Item label={label} name={name}>
			<MapEditor image={image} />
		</FormAnt.Item>
	</>
}

export const FormItems = ({label, name, render, maxItems, newItem, translatable = true}) => {
	const lang = useContext(LangContext)
	const role = useContext(RoleContext)
	if (!translatable && (!role.canFullEdit)) return null

	return <>
		<FormAnt.Item label={label}>
			<div style={{borderLeft: 'solid 1px lightgray', paddingLeft: '10px'}}>
				<FormAnt.List name={name}>
					{(items, {add, remove, move}) => <>
							<Flex vertical gap="small">
							{items.map((item) => <div key={item.key}>
								{render(item.name, (!role.canFullEdit ? null :
									<Space>
										{item.name > 0 ? <UpCircleTwoTone onClick={() => move(item.name, item.name-1)}/> : <UpCircleFilled style={{color: 'lightgray'}}/>}
										{item.name < items.length-1 ? <DownCircleTwoTone onClick={() => move(item.name, item.name+1)} /> : <DownCircleFilled style={{color: 'lightgray'}} />}
										<DeleteTwoTone onClick={() => remove(item.name)} />
									</Space>
								))}
							</div>
							)}
							{role.canFullEdit && (!maxItems || items.length < maxItems) &&
							<FormAnt.Item style={{marginBottom: 0}}>
								<Button onClick={() => add(newItem)}><PlusCircleTwoTone /></Button>
							</FormAnt.Item>
							}
							</Flex>
					</>}
				</FormAnt.List>
			</div>
		</FormAnt.Item>
	</>
}

const RichText = ({value, onChange}) => {
	const lang = useContext(LangContext)
	const role = useContext(RoleContext)

	const getContent = (value, lang) => {
		let result = value

		if (result && typeof result === 'object') {
			result = result[lang]
		} else if (typeof result === 'number') {
			result = result + ''
		} 

		if (result) result = result.replace(/([^>])\n([^<])/g, '$1<br>$2')

		return result
	}

	const update = (updatedValue, lang) => {

		const data = value
		let result = {}
		if (typeof data === "string") {
			// init FR value (it will be overriden or the current lang is also FR)
			result['FR'] = data
			result[lang] = updatedValue
		} else {
			result = {...data}
			result[lang] = updatedValue
		}

		onChange(result)
	}

	return <Flex vertical gap="small">
	<Flex gap="small">
		{lang !== role.mainLang && <>{role.mainLang}</>}
		<TextEditor className="ant-input"
			value={getContent(value, role.mainLang)}
			tinymceScriptSrc='/tinymce/tinymce.min.js'
			licenseKey='gpl'
			//apiKey="7plom4cxsr1d5jikegacgi0d0jorzfvh45jhblmehkhawcty"
			disabled= {!role.canFullEdit}
			inline={true}
			init={{
				entity_encoding: 'raw',
				block_formats: 'Paragraph=p;Header 1=h1',
				menubar: false,
				toolbar: false,
				plugins: [
					'quickbars',
					'lists'
				],
				quickbars_insert_toolbar: false,
				quickbars_selection_toolbar:
					'blocks | bold italic | bullist numlist | removeformat'
			}}
			onEditorChange={v => update(v, role.mainLang)}
		/>
		</Flex>
		{lang !== role.mainLang && <Flex gap="small">
			{lang}
			<TextEditor className="ant-input"
				value={getContent(value, lang)}
				tinymceScriptSrc='/tinymce/tinymce.min.js'
				licenseKey='gpl'
				//apiKey="7plom4cxsr1d5jikegacgi0d0jorzfvh45jhblmehkhawcty"
				inline={true}
				init={{
					entity_encoding: 'raw',
					block_formats: 'Paragraph=p;Header 1=h1',
					menubar: false,
					toolbar: false,
					plugins: [
						'quickbars',
						'lists'
					],
					quickbars_insert_toolbar: false,
					quickbars_selection_toolbar:
						'blocks | bold italic | bullist numlist | removeformat'
				}}
				onEditorChange={v => update(v, lang)}
			/>
			</Flex>
		}
	</Flex>
}

export const FormRichText = ({label, name, style}) => {
	return (
		<FormAnt.Item label={label} name={name} style={style}>
			<RichText />
		</FormAnt.Item>
	)
}


