import React, {useContext} from 'react'
import {FormText, FormSelect, FormItems, FormMap, FormImage} from 'components/form/FormItems.js'
import Anchors from '../../anchors/Anchors.js'
import String from 'components/string/String.js'
import {RenderModeContext, BleedContext} from 'utils/Context.js'
import './Map.css'
import Image from 'components/image/Image.js'
import { Flex, Select } from 'antd'

const RenderMode = ({data, mode}) => {
	const bleedContext = useContext(BleedContext)

	let BLEED = mode === 'small' ? 0 : bleedContext * 0.1
	let SIZE = mode === 'small' ? 1.2 : 8.9

	const compileInteractions = (interactions) => {

		if (!interactions) return null

		return interactions.map((i, idx) => (
			<span key={idx} className={`map-action icon-white map-action-${i.type}`} style={{top: `${i.y / 3 * SIZE + BLEED}cm`, left: `${i.x / 3 * SIZE + BLEED}cm`}}>
				{i.type && <span className={`icon icon-${i.type}`}></span>}
				{i.id && <String>{` ${i.id}`}</String>}
			</span>
		))
	}

	const compileWalls = (walls, type) => {

		if (!walls) return null

		const cellSize = SIZE/6
		const LINE_SIZE = 0.05
		const BLEED_FORCE = BLEED/LINE_SIZE
		const BORDER_FORCE = mode === 'small'? -1 : -2

		return walls.map((i, idx) => {
			
			if (!i) return null

			const lines = []

			let x1 = i.x1*cellSize
			let x2 = i.x2*cellSize
			let y1 = i.y1*cellSize
			let y2 = i.y2*cellSize

			if ( y1 === 0 && y2 === 0) {
				// create bleed + additional line
				for (let i = BORDER_FORCE; i < BLEED_FORCE; i++) {
					lines.push({x1: x1, x2: x2, y1: y1 - LINE_SIZE*i, y2: y2 - LINE_SIZE*i})
				}
			}
			
			if ( y1 === SIZE && y2 === SIZE) {
				for (let i = BORDER_FORCE; i < BLEED_FORCE; i++) {
					lines.push({x1: x1, x2: x2, y1: y1 + LINE_SIZE*i, y2: y2 + LINE_SIZE*i})
				}
			}
			if ( x1 === 0 && x2 === 0) {
				for (let i = BORDER_FORCE; i < BLEED_FORCE; i++) {
					lines.push({x1: x1 - LINE_SIZE*i, x2: x2 - LINE_SIZE*i, y1: y1, y2: y2})
				}
			}
			if ( x1 === SIZE && x2 === SIZE) {
				for (let i = BORDER_FORCE; i < BLEED_FORCE; i++) {
					lines.push({x1: x1 + LINE_SIZE*i, x2: x2 + LINE_SIZE*i, y1: y1, y2: y2})
				}
			}

			lines.push({x1: x1, x2: x2, y1: y1, y2: y2})


			const additionalLines = []
			
			lines.forEach(l => {
				if (
						((l.x2 === 0 || l.x2 === SIZE) && l.x1 !== l.x2) || 
						((l.y2 === 0 || l.y2 === SIZE) && l.y1 !== l.y2)
					) {
					additionalLines.push({x1: l.x2, x2: l.x2-(l.x1-l.x2)*2, y1: l.y2, y2: l.y2-(l.y1-l.y2)*2})
				}

					if (
						((l.x1 === 0 || l.x1 === SIZE) && l.x1 !== l.x2) || 
						((l.y1 === 0 || l.y1 === SIZE) && l.y1 !== l.y2)
					) {
						additionalLines.push({x1: l.x1, x2: l.x1-(l.x2-l.x1)*2, y1: l.y1, y2: l.y1-(l.y2-l.y1)*2})
					}
			})

			lines.push(...additionalLines)

			return lines.map((l,lidx) => <line key={`row${idx}-${lidx}`} className={`map-${type}`}
				x1={`${l.x1+BLEED}cm`} x2={`${l.x2+BLEED}cm`}
				y1={`${l.y1+BLEED}cm`} y2={`${l.y2+BLEED}cm`}
			/>)
		})
	}

	// style is needed because svg does not support it in css
	const buildGrid = (data, style) => {
		const walls = compileWalls(data.walls, 'wall')
		const doors = compileWalls(data.doors, 'door')
		const windows = compileWalls(data.windows, 'window')
		const obstacles = compileWalls(data.obstacles, 'obstacle')
		const danger = compileWalls(data.danger, 'danger')	
		const zones = compileWalls(data.zones, 'zone')

		return <svg style={style}>
			{zones}
			{obstacles}
			{danger}
			{doors}
			{windows}
			{walls}
		</svg>
	}
	
	const image = <Image className="map-bg" name={data.image} />
	
	const interactions = compileInteractions(data.interactions)
	const grid = buildGrid(data, {width: '100%', height: '100%', position: 'absolute'})

	return (			
		<div className={`map-mode-${mode?mode:'default'}`}>
			{image}
			{interactions}
			{grid}
			{mode !== 'small' && <Anchors data={data.anchors} />}
		</div>
	)
}

const EditMode = ({data}) => <>
	<FormText label="Replace card ID (if this map replaces another map)" name="replace" />
	<FormImage label="Image" name="image" />
	<FormItems label="Interactions" name="interactions" translatable={false} render={(name, options) => (
		/* eslint-disable jsx-a11y/accessible-emoji*/
		<Flex gap='small'>
			<FormSelect style={{flex: '0 0 200px', marginBottom: 0}} label={name===0 ? 'Type' : ''} name={[name, 'type' ]}>
				<Select.Option value="none"> - </Select.Option>
				<Select.Option value="look">👁️ Look</Select.Option>
				<Select.Option value="discuss">👄 Discuss</Select.Option>
				<Select.Option value="interact">✋ Interact</Select.Option>
				<Select.Option value="moveN">👣⬆️ move north</Select.Option>
				<Select.Option value="moveE">👣➡️ move east</Select.Option>
				<Select.Option value="moveS">👣⬇️ move south</Select.Option>
				<Select.Option value="moveW">👣⬅️ move west</Select.Option>
				<Select.Option value="move-external">👟 move (new zone)</Select.Option>
				<Select.Option value="material">⚙️ material</Select.Option>
				<Select.Option value="start">X Start</Select.Option>
				<Select.Option value="start-brick">🟢 Start Brick</Select.Option>
				<Select.Option value="start-djamal">🟡 Start Djamal</Select.Option>
				<Select.Option value="start-maeve">🔴 Start Maeve</Select.Option>
				<Select.Option value="start-selena">🔵 Start Selena</Select.Option>
				<Select.Option value="load">⚠️ Immediate event</Select.Option>
				<Select.Option value="event">⚡ Trigger event</Select.Option>
				<Select.Option value="card-event">🃏 Event card</Select.Option>
				<Select.Option value="marker">🏷️ Marker</Select.Option>
			</FormSelect>
			<FormText label={name===0 ? 'Value' : ''} name={[name, 'id']} style={{marginBottom: 0}} />
			<FormText label={name===0 ? 'X' : ''} name={[name, "x"]} style={{marginBottom: 0}} />
			<FormText label={name===0 ? 'Y' : ''} name={[name, "y"]} style={{marginBottom: 0}} />
			{options}
		</Flex>
	)} newItem={{
		type: 'none',
		id: '',
		x: 1.5,
		y: 1.5
	}}/>

	<Flex wrap="wrap" gap="small">
		<FormMap label="Zones" name="zones" image={data.image} />
		<FormMap label="Walls" name="walls" image={data.image} />
		<FormMap label="Doors" name="doors" image={data.image} />
		<FormMap label="Windows" name="windows" image={data.image} />
		<FormMap label="Obstacles" name="obstacles" image={data.image} />
		<FormMap label="Danger" name="danger" image={data.image} />
	</Flex>

	<Anchors name={['anchors']} data={data.anchors} type={'map'} />
</>

export default function Map(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}