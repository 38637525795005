import React, { useState, useEffect, useContext } from 'react'
import Card from '../components/card/Card.js'
import {FileContext, ImageContext, BleedContext, RoleContext} from '../utils/Context.js'
import {API} from '../utils/API.js'
import { Button, Input, Select, Space } from 'antd'

export default function Build({branch, file, cards, pushChange, setLoading, dev, print = false}) {	

	const URL_params = new URLSearchParams(window.location.search)
	const role = useContext(RoleContext)

	const [type, setType] = useState(URL_params.has('type') ? URL_params.get('type') : '',)
	const [filter, setFilter] = useState(URL_params.has('filter') ? URL_params.get('filter') : '')
	const [bleedEdge, setBleedEdge] = useState(URL_params.has('bleedEdge') ? URL_params.get('bleedEdge') : '0')

	useEffect(() => {

		URL_params.set('type', type)
		URL_params.set('filter', filter)
		URL_params.set('bleedEdge', bleedEdge)

		window.history.replaceState({test: '1'}, '', '?' + URL_params.toString())
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [type, filter, bleedEdge])

	const onCardChange = async (file, card) => {
		setLoading(true)
		const cards = await API.getContent(file, branch)

		// reset the print status
		card.print = null
		if (card.dev) card.dev.print = null
		
		// find the card and update it
		const result = cards.map(c => (c.id === card.id) ? card : c)

		pushChange(file, result)
	}

	const addCard = async (file) => {
		setLoading(true)

		const cards = await API.getContent(file, branch)
		
		// get latest id
		const lastCardId = cards.reduce((prev, c) => parseInt(c.id) > prev ? parseInt(c.id) : prev, 0)
		const newId = lastCardId + 1

		// check that we do not go over the chapter limit
		if (Math.floor(lastCardId / 100) !== Math.floor(newId / 100)) {
			window.alert('You reach the maximum of cards for this chapter...')
			setLoading(false)
			return
		}

		cards.push({
			id: `${newId}`,
			type: "narrative"
		})
		cards.push({
			id: `${newId}b`,
			type: "narrative"
		})

		pushChange(file, cards)		
	}

	const deleteCard = async (file, id) => {
		if (!window.confirm("Do you confirm deletion of the TWO faces of this card?")) return

		setLoading(true)

		id = id.match(/[0-9]+/)[0]

		// get cards and filter cards to remove
		let cards = await API.getContent(file, branch)
		cards = cards.filter(c => c.id !== id && c.id !== id+'b')

		pushChange(file, cards)
	}

	return <>
		{ !print && <Space className="form space-bottom">
				{role.canFullEdit &&
					<Select style={{ width: 120 }} defaultValue={bleedEdge} onChange={v => setBleedEdge(v)} options={[
						{value:0, label: '0'},
						{value:2, label: '0.2mm'},
						{value:3, label: '0.3mm'},
						{value:4, label: '0.4mm'},
						{value:5, label: '0.5mm'}
					]}>
					</Select>
				}

				<Select style={{ width: 200 }} defaultValue={type} onChange={v => setType(v)}>
					<Select.Option value=""> - All - </Select.Option>
					<Select.Option value="map">Map</Select.Option>
					<Select.Option value="event">Event</Select.Option>
					<Select.Option value="karma">Karma</Select.Option>
					<Select.Option value="object">Object</Select.Option>
					<Select.Option value="character">Character</Select.Option>
					<Select.Option value="narrative">Narrative</Select.Option>
				</Select>

				<Input value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filtre ID..."/>
			</Space>
		}
		<div className={`layout-container bleed-${bleedEdge}`}>
			{
				cards.map(c => {
					const id = c.id
			
					// is the card filtered ?
					if ((parseInt(id.match(/[0-9]+/)[0]) < parseInt(filter) || (type !== "" && !c.type.startsWith(type)) || (dev && !c.dev))) return null

					const transformation = print ? {width: 1122, height: 1122, c: 'at_max', q: 100, dpr: 5} : {width: 337, height: 337, c: 'at_max'}
			
					return (
						<ImageContext.Provider key={id} value={{path: `/main/`, transformation: [transformation]}}>
							<FileContext.Provider value={cards}>
								<BleedContext.Provider value={bleedEdge}>
									<div className="card-container">
										<Card onChange={card => onCardChange(file, card)} data={c} />
										{role.canFullEdit &&
											<div className="card-options print-hide">
												<Button onClick={()=>deleteCard(file, id)}>X</Button>
											</div>
										}
									</div>
								</BleedContext.Provider>
							</FileContext.Provider>
						</ImageContext.Provider>
					)
				})
			}
		
			{role.canFullEdit && <div className="card card-plus" onClick={() => addCard(file)}>+</div>}
		</div>
	</>
}
