import React, { useEffect, useState } from 'react'
import {LangContext, RoleContext, DevContext, Role} from '../utils/Context.js'
import {API} from '../utils/API.js'
import { registerHelper } from '../utils/Helper.js'
import { IKContext } from 'imagekitio-react'
import './normalize.css'
import './font.css'
import './App.css'
import Build from './Build.js'
import Print from './Print.js'
import Reorder from './Reorder.js'
import {Button, Flex, Segmented, Select, Space, Spin, Switch} from 'antd'

export default function App(props) {
	
	const URL_params = new URLSearchParams(window.location.search)

	const permissions = props.permissions

	const [file, setFile] = useState(URL_params.has('file') ? URL_params.get('file'): permissions.files[0])
	const [layout, setLayout] = useState(URL_params.has('layout') ? URL_params.get('layout') : 'build')
	const [printEmpty] = useState(URL_params.has('printEmpty') ? URL_params.get('printEmpty') === 'true' : false)
	const [branch, setBranch] = useState(URL_params.has('branch') ? URL_params.get('branch') : permissions.branches[0])
	//const [language, setLanguage] = useState(URL_params.has('lang') ? URL_params.get('lang') : permissions.lang)
	const [loading, setLoading] = useState(false)
	const [className, setClassName] = useState('')
	const [content, setContent] = useState(null)
	const [form] = useState(!URL_params.has('form') || URL_params.get('form') === 'true')
	const [dev, setDev] = useState(URL_params.has('dev') ? URL_params.get('dev') === 'true' : false)
	const [missingTranslation, setMissingTranslation] = useState(URL_params.has('missingTranslation') ? URL_params.get('missingTranslation') === 'true' : false)

	const language = permissions.projects.find(i => i.files.includes(file)).lang
	registerHelper(language)

	useEffect(() => {

		URL_params.set('file', file)
		URL_params.set('layout', layout)
		URL_params.set('lang', language)
		URL_params.set('form', form)
		URL_params.set('branch', branch)
		URL_params.set('dev', dev)
		URL_params.set('missingTranslation', missingTranslation)

		window.history.replaceState({test: '1'}, '', '?' + URL_params.toString()) //&filter=${filter}&type=${type}
		loadFiles()
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [file, layout, language, branch, dev, missingTranslation]) //type, filter, 

	const pushChange = async (file, data) => {
		setLoading(true)
		await API.pushContent(branch, file, data)

		// do it to try to fix the refresh bug
		window.setTimeout(loadFiles, 500)
		//loadFiles()
	}

	const loadFiles = async () => {
		setLoading(true)

		const data = await API.getContent(file, branch)
		const key = `${layout}-${file}-${branch}-${language}-${dev}-${missingTranslation}`

		switch (layout) {
			case 'build': 
			case 'rawprint':
				setContent(<Build key={key} branch={branch} file={file} cards={data} pushChange={pushChange} setLoading={setLoading} dev={dev} print={layout==='rawprint'}/>)
			break
			case 'print':
				setContent(<Print key={key} language={language} file={file} cards={data} dev={dev} />)
			break
			case 'reorder':
				setContent(<Reorder key={key} branch={branch} language={language} file={file} cards={data} dev={dev} pushChange={pushChange} setLoading={setLoading} />)
			break
			default:
				setContent(null)
		}

		setLoading(false)
		setClassName(layout === 'pdf' ? 'cards pdf A3' : layout + (printEmpty ? ' printEmpty' : '') + ' cards')
	}

	const tabs = []
	permissions.files.forEach(f => {
		const w = <div key={f}
			className={file !== f ? 'tab': 'tab selected'} 
			onClick={()=>setFile(f)}>{f}</div>

		tabs.push(w)
	})

	//<Segmented options={[permissions.mainLang, ...permissions.lang]} value={language} onChange={value => setLanguage(value) } />
	
	return <Spin spinning={loading} size='large' tip='Loading...'>

		{form && 
			<Flex justify='space-between' className='space'>
				<Space wrap>
					<Select 
						options={permissions.projects.filter(i => i.files.some(f => permissions.files.includes(f))).map(i => {return {value: i.name}})} 
						popupMatchSelectWidth={false} 
						value={permissions.projects.find(i => i.files.includes(file)).name} 
						onChange={v => setFile(permissions.projects.find(i => i.name === v).files[0])} />
					<Segmented options={permissions.files.filter(f => permissions.projects.find(i => i.files.includes(file)).files.includes(f))} value={file} onChange={value => setFile(value) } />
					{permissions.role === 'editor' && <Segmented options={['build', 'print', 'reorder']} value={layout} onChange={value => setLayout(value)}/>}
					{permissions.branches.length>1 && <Segmented options={permissions.branches} value={branch} onChange={value => setBranch(value) } />}
					{permissions.role === 'editor' && <span><Switch defaultChecked={ dev } onChange={checked => setDev(checked)} /> Dev only</span>}
					<span><Switch defaultChecked={ missingTranslation } onChange={checked => setMissingTranslation(checked)} /> Card with missing translations only</span>
				</Space>
				<Button className="logout" onClick={() => {localStorage.removeItem('token'); window.location.href='/'}}>Logout</Button>
			</Flex>
		}
		
		<div className={`${className} ${missingTranslation ? 'hide-missingTranslation': ''} theme-${permissions.projects.find(i => i.files.includes(file)).theme}`}>
			<IKContext
				publicKey="public_ZB6acNTURTGPteF56vS6yZ8/cfs="
				urlEndpoint="https://ik.imagekit.io/heredity"
				authenticationEndpoint="https://2vwfc8nlha.execute-api.us-east-1.amazonaws.com/prod/heredity?action=imagekit">
				<LangContext.Provider value={language}>
					<DevContext.Provider value={dev}>
						<RoleContext.Provider value={{...Role[permissions.role], mainLang: permissions.projects.find(i => i.files.includes(file)).mainLang}}>
							{content}
						</RoleContext.Provider>
					</DevContext.Provider>
				</LangContext.Provider>
			</IKContext>
		</div>

	</Spin>
}
