
import Handlebars from 'handlebars'

export function registerHelper(lang) {

	const safe = str => {
		return new Handlebars.SafeString(str)
	}

	const register = (name, callback) => {
		Handlebars.registerHelper(name, callback)
	}

	register('card', (id) => { 
		return safe(`<span class="helper helper-card"><span class="icon icon-card"></span> ${id}</span>`)
	})

	register('ref', (id) => { 
		return safe(`<span class="helper helper-ref">${id}</span>`)
	})

	register('flip', (id) => { 
		if(id instanceof Object) id = ""
		return safe(`<span class="helper helper-flip"><span class="icon icon-flip"></span> ${id}</span>`)
	})

	register('burn', (id) => { 
		if(id instanceof Object) id = ""
		return safe(`<span class="helper helper-burn"><span class="icon icon-burn"></span> ${id}</span>`)
	})

	register('discard', (id) => { 
		if(id instanceof Object) id = ""
		return safe(`<span class="helper helper-discard"><span class="icon icon-discard"></span> ${id}</span>`)
	})

	register('event', (id) => { 
		return safe(`<span class="helper helper-event icon-white"><span class="icon icon-card-event"></span> ${id}</span>`)
	})

	register('material', (id) => { 
		return safe(`<span class="helper helper-action">${typeof(id) === 'object' ? '' : id} <span class="icon icon-material"></span></span>`)
	})

	register('cdiscard', (id) => { 
		return safe(`<span class="helper helper-action">${typeof(id) === 'object' ? '' : id} <span class="icon icon-cdiscard"></span></span>`)
	})

	register('token', (id) => { 
		return safe(`<span class="helper helper-token">${typeof(id) === 'object' ? '' : id} <span class="icon icon-token"></span> </span>`)
	})

	register('move', (value) => { 
		if (value instanceof Object) value = ""
		return safe(`<span class="helper helper-move"><span class="icon icon-move"></span> ${value}</span>`)
	})

	register('movec', (value) => { 
		if (value instanceof Object) value = ""
		return safe(`<span class="helper helper-move-child"><span class="icon icon-move-child"></span> ${value}</span>`)
	})

	register('nofight', (value) => { 
		if (value instanceof Object) value = ""
		return safe(`<span class="helper helper-nofight"><span class="icon icon-nofight"></span> ${value}</span>`)
	})

	register('lineV', (value) => { 
		if (value instanceof Object) value = ""
		return safe(`<span class="helper helper-lineV"><span class="icon icon-lineV"></span> ${value}</span>`)
	})

	register('lineB', (value) => { 
		if (value instanceof Object) value = ""
		return safe(`<span class="helper helper-lineB"><span class="icon icon-lineB"></span> ${value}</span>`)
	})

	register('lineG', (value) => { 
		if (value instanceof Object) value = ""
		return safe(`<span class="helper helper-lineG"><span class="icon icon-lineG"></span> ${value}</span>`)
	})

	register('lineR', (value) => { 
		if (value instanceof Object) value = ""
		return safe(`<span class="helper helper-lineR"><span class="icon icon-lineR"></span> ${value}</span>`)
	})

	register('interact', () => { 
		return safe(`<span class="icon icon-interact"></span>`)
	})

	register('look', () => { 
		return safe(`<span class="icon icon-look"></span>`)
	})

	register('discuss', () => { 
		return safe(`<span class="icon icon-discuss"></span>`)
	})

	register('hostile', () => { 
		return safe(`<span class="icon icon-hostile"></span>`)
	})

	register('ally', () => { 
		return safe(`<span class="icon icon-ally"></span>`)
	})

	register('atq', (atq, dst) => { 
		return safe(`<span class="helper"><span class="icon icon-range${typeof(dst) === 'object' ? '-cac': ''}"></span> ${typeof(dst) === 'object' ? '' : dst} <span class="icon icon-atq"></span> ${atq}</span>`)
	})

	register('cac', () => { 
		return safe(`<span class="icon icon-range-cac"></span>`)
	})

	register('dgt', (atq) => { 
		return safe(`<span class="helper">${typeof(atq) === 'object' ? '' : atq} <span class="icon icon-dgt"></span></span>`)
	})

	register('def', (def) => { 
		return safe(`<span class="helper"><span class="icon icon-def"></span> ${def}</span>`)
	})

	register('icon', (icon) => { 
		return safe(`<span class="icon icon-${icon}"></span>`)
	})

	register('start', () => { 
		return safe(`<span class="icon icon-start"></span>`)
	})	

	register('start-maeve', () => { 
		return safe(`<span class="icon icon-start-maeve"></span>`)
	})	
	
	register('start-djamal', () => { 
		return safe(`<span class="icon icon-start-djamal"></span>`)
	})
	
	register('start-brick', () => { 
		return safe(`<span class="icon icon-start-brick"></span>`)
	})
	
	register('start-selena', () => { 
		return safe(`<span class="icon icon-start-selena"></span>`)
	})

	register('timeline', () => { 
		return safe(`<span class="icon icon-timeline"></span>`)
	})

	register('timemarker', () => { 
		return safe(`<span class="icon icon-timemarker"></span>`)
	})

	register('marker', (marker) => { 
		return safe(`<span class="helper helper-marker">${marker}</span>`)
	})

	register('warning', () => { 
		return safe(`<span class="icon icon-warning"></span>`)
	})

	register('si', () => { 
		let keyword = null
		switch(lang) {
			case 'EN': keyword = 'If'; break;
			case 'ZH': keyword = '如果'; break;
			case 'RU': keyword = 'если'; break;
			case 'IT': keyword = 'Se'; break;
			case 'DE': keyword = 'Wenn'; break;
			case 'PL': keyword = 'Jeśli'; break;
			default: keyword = 'Si'
		}

		return safe(`<span class="helper helper-if">${keyword}</span>`)
	})

	register('alors', () => { 
		let keyword = null
		switch(lang) {
			case 'EN': keyword = 'Then'; break;
			case 'ZH': keyword = '则'; break;
			case 'RU': keyword = 'то'; break;
			case 'IT': keyword = 'Allora'; break;
			case 'DE': keyword = 'Dann'; break;
			case 'PL': keyword = 'To'; break;
			default: keyword = 'Alors'
		}
		return safe(`<span class="helper helper-then">${keyword}</span>`)
	})

	register('sinon', () => { 
		let keyword = null
		switch(lang) {
			case 'EN': keyword = 'Else'; break;
			case 'ZH': keyword = '否则'; break;
			case 'RU': keyword = 'иначе'; break;
			case 'IT': keyword = 'Altrimenti'; break;
			case 'DE': keyword = 'Andernfalls'; break;
			case 'PL': keyword = 'INACZEJ'; break;
			default: keyword = 'Sinon'
		}
		return safe(`<span class="helper helper-else">${keyword}</span>`)
	})

	register('ou', () => { 
		let keyword = null
		switch(lang) {
			case 'EN': keyword = 'Else'; break;
			case 'ZH': keyword = '否则'; break;
			case 'RU': keyword = 'иначе'; break;
			case 'IT': keyword = 'Altrimenti'; break;
			case 'DE': keyword = 'Andernfalls'; break;
			case 'PL': keyword = 'INACZEJ'; break;
			default: keyword = 'Sinon'
		}
		return safe(`<span class="helper helper-or">${keyword}</span>`)
	})

	register('famille', () => { 
		let keyword = null
		switch(lang) {
			case 'EN': keyword = 'Family'; break;
			case 'ZH': keyword = '家庭'; break;
			case 'RU': keyword = 'семья'; break;
			case 'IT': keyword = 'Famiglia'; break;
			case 'DE': keyword = 'Familie'; break;
			case 'PL': keyword = 'Rodzina'; break;
			default: keyword = 'Famille'
		}
		return safe(`<span class="helper helper-family"><span class="icon icon-card"></span> <span>${keyword}</span></span>`)
	})

	register('insecte', () => { 
		return safe(`<span class="icon icon-insecte"></span>`)
	})

}