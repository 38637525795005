import React, {useContext} from 'react'
import './NPC.css'
import Anchors from 'components/anchors/Anchors.js'
import {FormSelect, FormText, FormItems, FormRichText, FormImage} from 'components/form/FormItems.js'
import String, {getStringValue} from 'components/string/String.js'
import {RenderModeContext, LangContext, RoleContext} from 'utils/Context.js'
import Image from 'components/image/Image.js'
import { Flex, Form, Select } from 'antd'

export const RenderMode = ({data, className}) => {
	const lang = useContext(LangContext)
	const role = useContext(RoleContext)

	let actions = data.actions ? data.actions.map((a, idx) => (
		<div key={idx} className="npc-action">
			<String>{a}</String>
		</div>
	)) : null

	const special = getStringValue(data.special, lang, role.mainLang) ? (
		<div className="npc-special">
			<div className={`npc-special-icon`}></div>
			<String className='npc-special-content'>{data.special}</String> 
		</div>
	): null

	const deathrattle = getStringValue(data.deathrattle, lang, role.mainLang) ? (
		<div className="npc-deathrattle icon-white">
			<div>
				<String>{data.deathrattle}</String>
			</div>
		</div>
	): null

	const behavior = `npc-behavior npc-${data.behavior ? data.behavior : data.hostile?'hostile':''}`

	const image = <Image className="npc-avatar" name={data.image} />

	return (
		<div className={`npc npc-${data.subtype} ${className}`}>				
			{image}
			<div className="npc-content">
				<div className="npc-name icon-white">
					<String>{data.name}</String>
				</div>
				<div className="npc-def icon-white">{data.def}</div>
				<div className="npc-hp icon-white">{data.hp}</div>
				<div className={behavior}></div>
				<div className="npc-actions icon-white">
					{actions}
					{special}
				</div>
				{deathrattle}
			</div>
			<Anchors data={data.anchors} />
		</div>
	)
}

const NPCType = ({value, onChange}) => {

	const convertValue = (obj) => {

		if (!obj) return '{}'

		const keys = Object.keys(obj).sort()
		const sortedObj = {}
		for (const key of keys) {
			sortedObj[key] = obj[key]
		}
		return JSON.stringify(sortedObj)
	}

	return <>
		<Select value={convertValue(value)} onChange={value => onChange(JSON.parse(value))} options={[
			{value: '{}', label: ' - '},
			{value: convertValue({W: 'right', E: 'right', S: 'organic'}), label: 'Organic'},
			{value: convertValue({W: 'right', E: 'right', S: 'mechanic'}), label: 'Mechanic'}
		]}>
		</Select>
	</>
}

const EditMode = ({name, data}) => {

	const role = useContext(RoleContext)
	const lang = useContext(LangContext)

return <>
	<Flex gap="small">
		<FormRichText label="Name" style={{flex: 1}} name={[...name, 'name']} />
		<FormText label="Health" name={[...name, 'hp']} style={{flex: '0 0 40px'}} />
		<FormText label="Def" name={[...name, 'def']} style={{flex: '0 0 40px'}} />
	</Flex>
	<FormImage label="Image" name={[...name, 'image']} />
	<Flex gap="small">
		<FormSelect label="Faction" style={{flex: 1}} name={[...name, 'subtype']}>
			<Select.Option value=""> - </Select.Option>
			<Select.Option value="adorateur">Adorateur</Select.Option>
			<Select.Option value="technocrate">Technocrate</Select.Option>
			<Select.Option value="scavenger">Scavenger</Select.Option>				
		</FormSelect>
		{role.canFullEdit && <>
			<Form.Item style={{flex: 1}} label="Type" name={[...name, 'anchors']}>
				<NPCType />
			</Form.Item>
		</>}
		<FormSelect label="Behavior" style={{flex: 1}} name={[...name, 'behavior']}>
			<Select.Option value="">Neutral</Select.Option>
			<Select.Option value="hostile">Hostile</Select.Option>
			<Select.Option value="ally">Ally</Select.Option>
		</FormSelect>
	</Flex>
	<FormRichText label="Deathrattle" name={[...name, 'deathrattle']} />
	<FormRichText label="Special" name={[...name, 'special']} />
	<FormItems label="Actions" name={[...name, 'actions']} render={(itemName, options) => <>
		<Flex gap="small">
			<FormRichText name={itemName} style={{flex: 1, marginBottom: 0}} />
			{options}
		</Flex>
	</>} />
</>
}

export default function NPC(props) {
	const renderMode = useContext(RenderModeContext)
	return renderMode.edit ? <EditMode {...props} /> : <RenderMode {...props} />
}
